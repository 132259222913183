import React, { useContext } from 'react';
import { lightTheme, darkTheme } from '../styles/Theme';
import { FaSun as Sun, FaMoon as Moon } from 'react-icons/fa';
import ThemeContext from '../components/ThemeContext';
import styled from 'styled-components';

const ToggleButtonStyles = styled.button`

    margin-bottom: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    &:hover {
        background-color: transparent;
    }
    @media (max-width: 635px) {
        margin-top: 1.5rem;
        justify-content: flex-start;
    }

    svg {
        color: ${({ theme }) => theme === lightTheme ? `#878A91` : `#f9d71c`};
    }

`;

export default function DarkLightToggle() {
    const [theme, setTheme] = useContext(ThemeContext);

    function toggleTheme() {
        if(theme === lightTheme) {
            setTheme(darkTheme);
        } else {
            setTheme(lightTheme);
        }
    }
    return (
        <>
            <ToggleButtonStyles onClick={toggleTheme} theme={theme} aria-label={`toggle ${theme === lightTheme ? 'dark' : 'light'} theme`}>
                {theme === lightTheme ? <Moon /> : <Sun />}
            </ToggleButtonStyles>
        </>
    )
}