import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import ThemeContext from '../components/ThemeContext';
import darkLogo from '../assets/images/nhaug-white-green-4.png';
import lightLogo from '../assets/images/nikolas-haug-black.png';
import { lightTheme, darkTheme } from '../styles/Theme';
import styled from 'styled-components';
import DarkLightToggle from './DarkLightToggle';

const HeaderStyles = styled.header`

    padding: 3.5rem 0;

`;

const NavbarStyles = styled.nav`

    display: flex;
    align-items: center;

    .logo {
        padding: 0;
        width: 10rem;
        margin-left: 0;

        img {
            display: block;
            width: 100%;
        }
    }

    ul {
        display: flex;
        margin-left: auto;
        transition: all .5s ease-in-out;
        @media (max-width: 635px) {
            transform: ${ props => props.open ? `translateX(0)` : `translateX(100%)` };
            width: 60%;
            position: fixed;
            right: 0;
            top: 0;
            height: 100vh;
            padding-top: 10rem;
            padding-left: 2.5rem;
            flex-direction: column;
            background: ${ props => props.theme === darkTheme ? 'var(--color-black)' : 'var(--color-white)' };
            box-shadow: ${ props => props.open ? `-1px 0 10px rgb(255 255 255 / 40%)` : 'none' };
            border-left: ${ props => props.open ? `.5px solid rgba(0, 0, 0, .1)` : 'none' };
            z-index: 999;

            li {
                margin-bottom: 2.5rem;
            }
        }
    }

    li {
        margin: 0 0 0 2rem;
    }

    a {
        color: var(--white);
        font-size: 1.8rem;

        &.active-link {
            border-bottom: 5px solid var(--color-green-1);
            padding-bottom: .5rem;
        }
    }

    .toggle-item {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

`;

const NavToggleStyles = styled.button`

    display: none;
    position: ${ props => props.open ? `fixed` : `absolute` };
    width: 5rem;
    right: 0;
    z-index: 9999;
    justify-content: center;
    background: transparent;
    border: none;
    margin-right: 1.5rem;
    &:focus,
    &:active,
    &:hover {
        background: transparent;
        border: none;
    }
    @media (max-width: 635px) {
        display: flex;
    }

    span,
    span::before,
    span::after {
        position: absolute;
        content: "";
        width: 28px;
        height: 2.5px;
        background: ${ props => props.theme === lightTheme ? 'var(--color-black)' : 'var(--color-white)' };
        border-radius: 20px;
        transition: all .5s;
    }
    span::before {
        top: -8px;
        right: 0;
    }
    span::after {
        top: 8px;
        right: 0;
    }

    .active {
        background-color: transparent;
        &::before {
            top: 0;
            transform: rotate(-45deg);
        }
        &::after {
            top: 0;
            transform: rotate(45deg);
        }
    }

`;

export default function Header() {
    const [theme] = useContext(ThemeContext);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <HeaderStyles>
            <NavbarStyles open={isOpen} theme={theme}>
                <Link to={'/'} className="logo">
                    <img src={theme === darkTheme ? darkLogo : lightLogo} alt=""/>
                    <h1 className="visually-hidden">Nikolas Haug - Web Developer</h1> 
                </Link>
                <NavToggleStyles open={isOpen} theme={theme} onClick={() => setIsOpen(!isOpen)} aria-label="navigation menu">
                    <span className={isOpen ? 'active' : ''}></span>
                </NavToggleStyles>
                <ul>
                    <li><Link to={'/'} activeClassName="active-link" onClick={() => { if(isOpen) { setIsOpen(false) } }}>Home</Link></li> 
                    <li><Link to={'/about'} activeClassName="active-link" onClick={() => { if(isOpen) { setIsOpen(false) } }}>About</Link></li> 
                    <li><Link to={'/projects'} activeClassName="active-link" onClick={() => { if(isOpen) { setIsOpen(false) } }}>Projects</Link></li> 
                    <li><Link to={'/contact'} activeClassName="active-link" onClick={() => { if(isOpen) { setIsOpen(false) } }}>Contact</Link></li> 
                    <li className="toggle-item">
                        <DarkLightToggle /> 
                    </li>
                </ul>
            </NavbarStyles>
        </HeaderStyles>
    )
}