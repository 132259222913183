import React from 'react';
import {
    TransitionGroup,
    Transition as ReactTransition
} from 'react-transition-group';

const timeout = 500;
const getTransitionStyles = {
    entering: {
        position: 'fixed',
        width: 0,
        height: '10px',
        background: 'var(--color-green-1)'
    },
    entered: {
        position: 'fixed',
        transition: `all ${timeout}ms ease-in-out`,
        width: '100%',
        height: '10px'
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        width: '100%',

    }
}

class Transition extends React.PureComponent {
    render() {
        const { children, location } = this.props;
        return (
            <TransitionGroup>
                <ReactTransition
                    key={location.pathname}
                    timeout={{
                        enter: timeout,
                        exit: timeout
                    }}
                >
                    {status => (
                        <div style={{...getTransitionStyles[status]}}>
                            {children}
                        </div>
                    )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}

export default Transition;