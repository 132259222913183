import React, { useState } from 'react';
import { darkTheme } from '../styles/Theme';

// Create a theme context
const ThemeContext = React.createContext();

export function ThemeProvider({ children }) {
    // we need to stick state in here
    const [theme, setTheme] = useState(darkTheme);
    return (
        <ThemeContext.Provider value={[theme, setTheme]}>
            {children}
        </ThemeContext.Provider>
    )
}

export default ThemeContext;