import React from 'react';
import { AiFillGithub as GithubIcon } from 'react-icons/ai';
import { RiLinkedinBoxLine as LinkedinIcon } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import styled from 'styled-components';

const FooterStyles = styled.footer`

    text-align: center;
    padding: 3.5rem 0;

    .copyright {
        font-size: 1.2rem;
        font-weight: 300;
    }

`;

export default function Footer() {
    return (
        <>
            <FooterStyles>
                <div className="social-links">
                    <a href="https://github.com/nikolas-haug" target="_blank" rel="noreferrer" aria-label="github footer link">
                        <GithubIcon aria-hidden="true" />
                    </a>
                    <a href="https://www.linkedin.com/in/nikolas-haug-02b016158/" target="_blank" rel="noreferrer" aria-label="linkedin footer link">
                        <LinkedinIcon aria-hidden="true" />
                    </a>
                    <a href="mailto:nikolas@nikolashaug.com" aria-label="email footer link">
                        <MdEmail aria-hidden="true" />
                    </a>
                </div>
                <div className="copyright">
                    &copy; { new Date().getFullYear() } - Nikolas Haug
                </div>
            </FooterStyles>
        </>
    )
}