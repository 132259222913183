import React, { useContext } from 'react';
import 'normalize.css';
import ThemeContext from '../components/ThemeContext';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Header from './Header';
import Footer from './Footer';
import Transition from './Transition';
import LineTransition from './LineTransition';

const ContainerStyles = styled.div`

    max-width: var(--container-size);
    margin: 0 auto;
    padding: 0 1.5rem;

`;

export default function Layout({ children, location  }) {
    const [theme] = useContext(ThemeContext);
    return (
        <>
            <GlobalStyles theme={theme} />
            <Typography />
            <LineTransition location={location} />
            <ContainerStyles>
                <Header />
                    <Transition location={location}>
                        <main>
                            { children }
                        </main>
                    </Transition>
                <Footer />
            </ContainerStyles>
        </>
    )
}